<template>
  <div >
    <l-map
        :zoom="zoom"
        :max-zoom="maxZoom"
        :center="centered.coordinates"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        style="z-index: 0; height: 437px; "
        :options="{scrollWheelZoom: false}"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />

      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>

      <l-control>
        <div class="text-center">
          <v-menu
              v-model="showLayers1"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-x
              max-height="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="indigo"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-lg"
                  icon
                  large
                  outlined
                  elevation="10"
              >
                <v-icon>mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-card width="500px" elevation="16" class="rounded-lg">
              <v-list>
                <v-list-item class="no-gutters" dense>
                  <v-list-item-content>
                    <v-list-item-title v-if="lang==='id'">{{itemModified.kode2}}</v-list-item-title>
                    <v-list-item-title v-if="lang==='en'">{{itemModified.kode2En}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t('dataPrimerDanPendukung')}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="showLayers1 = !showLayers1"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsSpaMain" :key="index"
                  >
                    <v-checkbox
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasanInfraGeoJson(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsSpaExt" :key="index"
                  >
                    <v-checkbox
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        @change="valueChangedLuasanInfraGeoJson(item)"
                        class="caption"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>


            </v-card>
          </v-menu>
        </div>

      </l-control>

      <l-control>
        <div class="text-center">
          <v-menu
              v-model="showLayers2"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-x
              max-height="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-lg"
                  icon
                  large
                  outlined
                  elevation="10"
              >
                <v-icon>mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-card width="500px" elevation="16" class="rounded-lg">
              <v-list>
                <v-list-item class="no-gutters" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('infrastruktur')}} & {{$t('sektor')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="showLayers2 = !showLayers2"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFInfrastructureTypeActiveOnly" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedInfraTypeMarker(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedInfraTypeMarker(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFSectorTypeTrueOnly" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedKomoditiTypeMarker(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedKomoditiTypeMarker(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text v-if="false">
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFKomoditiType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedKomoditiTypeMarker(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedKomoditiTypeMarker(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>
          </v-menu>
        </div>

      </l-control>

      <l-marker
          :key="computedCurrentMarker.id"
          :lat-lng="computedCurrentMarker.coordinates"
          ref="marker"
      >

        <l-icon
            :icon-size="computedCurrentMarker.iconSize"
            :icon-anchor="computedCurrentMarker.iconAnchor"
            :icon-url="computedCurrentMarker.iconUrl"
        />

        <l-popup>
          <v-flex class="d-flex" v-if="lang==='id'">
            {{ itemModified.kode2 }}
          </v-flex>
          <v-flex class="d-flex" v-if="lang==='en'">
            {{ itemModified.kode2En }}
          </v-flex>
          <v-flex class="mt-1" v-if="itemModified.district !==undefined">
            <div class="caption font-weight-bold ">
              {{ itemModified.district }}
              ,
              <span v-if="! lookupFSubArea(itemModified.fsubAreaBean)">
                {{ itemModified.city }}
              </span>
              <span v-else>
                <router-link :to="`/public-profil-daerah-detil/${lookupFSubArea(itemModified.fsubAreaBean).fareaBean}`" style="text-decoration: none">
                  <v-btn color="blue" dark x-small class="rounded-lg text-decoration-underline" plain>
                    {{ lookupFArea(lookupFSubArea(itemModified.fsubAreaBean).fareaBean).description }}
                  </v-btn>
                </router-link>
              </span>

            </div>
          </v-flex>
          <v-flex class="mt-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(itemModified)" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>
          </v-flex>

        </l-popup>

      </l-marker>

      <l-geo-json
          v-for="(item, index) in computedItemsSpaMainGeojson" :key="index"
          :geojson="item.data"
          :options="options"
          :options-style="styleFunction"
      >
      </l-geo-json>

      <l-marker
          v-for="marker in computedInfraMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >
        <l-tooltip class="rounded-lg">
          <div class="font-weight-bold">
            {{ lookupFInfrastructure(marker.id).description }}
          </div>
          <div>
            {{ lookupFSubArea(lookupFInfrastructure(marker.id).fsubAreaBean).description }}
            -
            {{ lookupFArea(lookupFSubArea(lookupFInfrastructure(marker.id).fsubAreaBean).fareaBean).description }}
          </div>
        </l-tooltip>

        <l-icon
          :icon-size="marker.iconSize"
          :icon-url="marker.iconUrl"
        />
        <l-popup>
          <v-flex class="d-flex" >
            {{ lookupFInfrastructure(marker.id).description }}
          </v-flex>
          <v-flex class="mt-1" v-if="lookupFInfrastructure(marker.id).district !==undefined">
            <div class="caption font-weight-light">
              {{ lookupFInfrastructure(marker.id).district }}
            </div>
          </v-flex>

          <v-flex class="mt-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(lookupFInfrastructure(marker.id))" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>
          </v-flex>

        </l-popup>
      </l-marker>

      <l-marker
          v-for="marker in computedKomoditiMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >
        <l-tooltip class="rounded-lg">
          <div class="font-weight-bold">
            {{ lookupFKomoditi(marker.id).description }}
          </div>
          <div>
            {{ lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).description }}
            -
            {{ lookupFArea(lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).fareaBean).description }}
          </div>
        </l-tooltip>

        <l-icon
            :icon-size="marker.iconSize"
            :icon-url="marker.iconUrl"
        />
        <l-popup>
          <v-flex class="d-flex" >
            {{ lookupFKomoditi(marker.id).description }}
          </v-flex>
          <v-flex class="mt-1" v-if="lookupFKomoditi(marker.id).fsubAreaBean !==undefined">

            <div>
              <span class="font-weight-bold">
                {{ lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).description }}
              </span>
              -
              {{ lookupFArea(lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).fareaBean).description }}
            </div>
          </v-flex>

          <v-flex class="mt-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(lookupFKomoditi(marker.id))" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>
          </v-flex>

        </l-popup>
      </l-marker>

      <l-control-polyline-measure :options="{ showUnitControl: true }" position="topleft"/>

    </l-map>
  </div>
</template>

<script>
import { Icon } from "leaflet";
import { LMap, LTileLayer,
  LControl,
  LControlLayers,
  LMarker,
  LPopup, LTooltip,
  LIcon,
  LGeoJson } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';

import FileService from "@/services/apiservices/file-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";
import FKomoditiService from "@/services/apiservices/f-komoditi-service";

delete Icon.Default.prototype.Default;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "PotensiDetailMap",
  components: {
    LMap,
    LTileLayer,
    LControl,
    LControlLayers,
    LMarker,
    LIcon,
    LPopup,
    LTooltip,
    LGeoJson,
    LControlPolylineMeasure
  },
  props:{
    itemModified: Object,
    itemsFile:[]
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    itemsFile:{
      handler: function (value, oldValue){
        // console.log(oldValue.length)
        // console.log(value)

        if (oldValue.length===0){
          // console.log(value)
          value.forEach(item => {
            if (item.selected){
              this.valueChangedInfraTypeMarker(item)
            }
          })
        }
      }

    }

  },
  data() {
    return {
      enableTooltip: true,
      zoom: 14,
      maxZoom: 18,
      // center_TidakDipakai: latLng(47.41322, -1.219482),//Kebalik bos
      centered:{id: 1, coordinates: [-7.250445, 112.768845]},
      // centered:{id: 1, coordinates: [47.313220, -1.319482]},

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],


      showLayers1: false,
      showLayers2: false,

      geojson: null,
      itemSpaMainGeojson: [],

      finfratructures:[],
      finfratructureTypesSelected:[],

      fkomodities:[],
      fsectorTypesSelected:[],
      fkomoditiTypesSelected:[]

    };
  },
  computed:{
    computedCurrentMarker(){
      let marker = {id: 1, coordinates: [this.itemModified.lat, this.itemModified.lon]}

      const sectorType = this.lookupFSectorType(this.itemModified.fsectorTypeBean)
      const sectorTypeMarker1 = sectorType.markerImage1
      marker = {id: this.itemModified.id, coordinates: [this.itemModified.lat, this.itemModified.lon],
        iconUrl: this.lookupImageMarkerUrl(sectorTypeMarker1),
        iconSize: [45, 65],
      }

      /**
       * Melakukan Update secara Programming ke Center
       */
      if (this.itemModified.lat !=0 && this.itemModified.lon !=0) {
        this.$nextTick(() => {
          this.centered = marker
          this.$refs.marker.mapObject.openPopup()
        })
      }

      return marker
    },

    computedInfraMarkers(){
      let markers = []

      if (this.finfratructures !==undefined){
        this.finfratructures.forEach( item =>{

          // console.log(JSON.stringify(item))

          if (item.lat !==0 && item.lon !==0){

            const infrastructureType = this.lookupFInfrastructureType(item.finfrastructureTypeBean)
            const infrastructureTypeMarker1 = infrastructureType.markerImage1

            let marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: this.lookupImageMarkerUrl(infrastructureTypeMarker1),
              // iconSize: [23, 35],
              iconSize: [28, 35],
            }

            markers.push(marker)
          }
        })
      }

      return markers
    },

    computedKomoditiMarkers(){
      let markers = []

      if (this.fkomodities !==undefined){
        this.fkomodities.forEach( item =>{

          // console.log(JSON.stringify(item.lat))

          if (item.lat !==0 && item.lon !==0){

            const komoditiType = this.lookupFKomoditiType(item.fkomoditiTypeBean)
            const komoditiTypeMarker1 = komoditiType.markerImage1

            let marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: this.lookupImageMarkerUrl(komoditiTypeMarker1),
              // iconSize: [23, 35],
              iconSize: [28, 28],
            }
            markers.push(marker)
          }
        })
      }

      // console.log(JSON.stringify(this.fkomodities))
      // console.log(JSON.stringify(markers))

      return markers
    },

    computedItemsSpaMain(){
      return this.itemsFile.filter(item => item.potensiType===4 && item.fileType=="SPA")
    },
    computedItemsSpaMainGeojson(){
      return this.itemSpaMainGeojson
    },

    computedItemsSpaExt(){
      return this.itemsFile.filter(item => item.potensiType===5 && item.fileType=="SPA")
    },

    computedItemsFSectorTypeTrueOnly(){
      return this.$store.state.potensi.itemsFSectorType.filter(x => x.statusActive==true)
    },
    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },

    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType.filter(x => x.statusActive===true)
    },

    computedItemsFInfrastructureTypeActiveOnly(){
      return this.$store.state.potensi.itemsFInfrastructureType.filter(x => x.statusActive===true)
    },
    computedItemsFInfrastructureType(){
      return this.$store.state.potensi.itemsFInfrastructureType
    },
    computedItemsFSubArea(){
      return this.$store.state.potensi.itemsFSubArea
    },
    computedItemsFArea(){
      return this.$store.state.potensi.itemsFArea
    },

    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },

    styleFunction() {

      let lineColor = "red";
      let fillColor = "red";

      return value => {
        // console.log(value.fillColor)

        // if (value){
        //   lineColor = value.lineColor
        //   fillColor = value.fillColor
        //
        //   try {
        //     console.log(JSON.stringify(value))
        //
        //     if (value.properties.pola_ruang != undefined){
        //       fillColor = this.lookupGeoJsonColor(value.properties.pola_ruang)
        //     }else if (value.properties.Rencana != undefined){
        //       fillColor = this.lookupGeoJsonColor(value.properties.Rencana)
        //     }
        //   }catch (e) {
        //     e.toString()
        //   }
        //
        // }

        // console.log(JSON.stringify(value.properties))

        lineColor = 'blue'
        fillColor =  'blue'

        // try{
          if (value.properties.pola_ruang !== undefined) {
            lineColor = this.lookupGeoJsonColor(value.properties.pola_ruang)
            fillColor = this.lookupGeoJsonColor(value.properties.pola_ruang)
          }if (value.properties.POLA_RUANG !== undefined){
              lineColor = this.lookupGeoJsonColor(value.properties.POLA_RUANG)
              fillColor = this.lookupGeoJsonColor(value.properties.POLA_RUANG)
          }else if (value.properties.Rencana !== undefined){
            lineColor = this.lookupGeoJsonColor(value.properties.Rencana)
            fillColor = this.lookupGeoJsonColor(value.properties.Rencana)
          }else if (value.properties.LAYER !== undefined){
            lineColor = this.lookupGeoJsonColor(value.properties.LAYER)
            fillColor = this.lookupGeoJsonColor(value.properties.LAYER)
          }else if (value.properties.NAMOBJ !== undefined){
            lineColor = this.lookupGeoJsonColor(value.properties.NAMOBJ)
            fillColor = this.lookupGeoJsonColor(value.properties.NAMOBJ)
          }else if (value.properties.Orde_I !== undefined){
            lineColor = this.lookupGeoJsonColor(value.properties.Orde_I)
            fillColor = this.lookupGeoJsonColor(value.properties.Orde_I)
          }else if (value.properties.KETERANGAN !== undefined){
            lineColor = this.lookupGeoJsonColor(value.properties.KETERANGAN)
            fillColor = this.lookupGeoJsonColor(value.properties.KETERANGAN)
        }else if (value.properties.Keterangan !== undefined){
          lineColor = this.lookupGeoJsonColor(value.properties.Keterangan)
          fillColor = this.lookupGeoJsonColor(value.properties.Keterangan)
          }
        // }catch (e) {
        //   console.log(e)
        // }

        return {
          weight: 1,
          color: lineColor,
          opacity: 0.4,
          fillColor: fillColor,
          fillOpacity: 0.4
        };
      };

    },

    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        // console.log(feature)

        layer.bindTooltip(
            "<div>" +
            this.jsonToHtmlTable(feature.properties) +
            "</div>",
            { permanent: false, sticky: true }
        );
      };
    },

    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },

  },
  methods: {

    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    changeSelection(value){
      value.toString()

      const tipe = this.lookupFInfrastructureType(this.itemModified.finfrastructureTypeBean)
      const theIconUrl = this.lookupImageMarkerUrl(tipe.markerImage1)
      const newMarker =
          {
            id: 1,
            iconUrl: theIconUrl,
            coordinates: [value.lat, value.lon],
            iconSize: [45, 65],
            // iconAnchor: [16, 37]
          }

      this.$nextTick(() => {
        this.itemModified = Object.assign({}, value)
        this.currentMarker = newMarker
        this.zoom = 10
        this.$refs.marker.mapObject.openPopup()

      })

    },
    lookupFPotensi (fpotensiBean) {
      const items = this.computedPotensies.filter(x => x.id===fpotensiBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.computedItemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfrastructure (finfrastructureBean) {
      const str = this.finfratructures.filter(x => x.id===finfrastructureBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFInfrastructureType (finfrastructureTypeBean) {
      const str = this.computedItemsFInfrastructureType.filter(x => x.id===finfrastructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFKomoditi (fkomoditiBean) {
      const str = this.fkomodities.filter(x => x.id===fkomoditiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.computedItemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.computedItemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFArea (fareaBean) {
      const str = this.computedItemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },

    lookupGeoJsonColor(value){
      let theColor = 'blue'

      if (value.toUpperCase().includes('PERMUKIMAN')){
        theColor = '#c9791c'
      }else if (value.toUpperCase().includes('PERUMAHAN')){
        theColor = '#e0af75'
      }else if (value.toUpperCase().includes('PEDESAAN')){
        theColor = '#e0af75'
      }else if (value.toUpperCase().includes('RTH')){
        theColor = '#c74a6f'
      }else if (value.toUpperCase().includes('TAMBAK')){
        theColor = '#e3cbaf'

      }else if (value.toUpperCase().includes('PERUNTUKAN INDUSTRI')){
        theColor = '#2364a1'
      }else if (value.toUpperCase().includes('INDUSTRI')){
        theColor = '#2364a1'

      }else if (value.toUpperCase().includes('PERKEBUNAN')){
        theColor = '#8fba49'
      }else if (value.toUpperCase().includes('PERTANIAN')){
        theColor = '#8fba49'
      }else if (value.toUpperCase().includes('HOLTIKULTURA')){
        theColor = '#647d3c'

      }else if (value.toUpperCase().includes('HUTAN PRODUKSI')){
        theColor = '#176b11'
      }else if (value.toUpperCase().includes('LINDUNG GEOLOGI')){
        theColor = '#3e9438'
      }else if (value.toUpperCase().includes('HUTAN')){
        theColor = '#6b9968'
      }else if (value.toUpperCase().includes('TAMAN NASIONAL')){
        theColor = '#6b9968'

      }else if (value.toUpperCase().includes('TRANSPORTASI')){
        theColor = '#9c468d'

      }else if (value.toUpperCase().includes('RAWAN BENCANA')){
        theColor = '#9c1720'

      }else if (value.toUpperCase().includes('PERDAGANGAN')){
        theColor = '#9c468d'
      }else if (value.toUpperCase().includes('PARIWISATA')){
        theColor = '#7ba2d1'

      }else if (value.toUpperCase().includes('PANTAI')){
        theColor = '#7ba2d1'
      }else if (value.toUpperCase().includes('DANAU')){
        theColor = '#889cb5'
      }else if (value.toUpperCase().includes('EMPADAN SUNGAI')){
        theColor = '#ccd4de'
      }else if (value.toUpperCase().includes('SUNGAI')){
        theColor = '#ccd4de'
      }else if (value.toUpperCase().includes('BADAN AIR')){
        theColor = '#ccd4de'
      }else {
        theColor = 'yellow'
      }
      return theColor
    },

    lookupGeoJsonColor_X(value){
      let theColor = 'blue'

      if (value.includes('Permukiman')){
        theColor = '#c9791c'
      }else if (value.includes('Perumahan')){
        theColor = '#e0af75'
      }else if (value.includes('RTH')){
        theColor = '#e0af85'

      }else if (value.includes('Peruntukan Industri')){
        theColor = '#2364a1'

      }else if (value.includes('perkebunan')){
        theColor = '#8fba49'
      }else if (value.includes('Pertanian')){
        theColor = '#8fba49'
      }else if (value.includes('Holtikultura')){
        theColor = '#647d3c'

      }else if (value.includes('Hutan Produksi')){
        theColor = '#176b11'
      }else if (value.includes('Lindung Geologi')){
        theColor = '#3e9438'
      }else if (value.includes('Hutan')){
        theColor = '#6b9968'
      }else if (value.includes('Taman nasional')){
        theColor = '#6b9968'

      }else if (value.includes('Transportasi')){
        theColor = '#9c468d'

      }else if (value.includes('Rawan Bencana')){
        theColor = '#9c1720'

      }else if (value.includes('Pantai')){
        theColor = '#7ba2d1'
      }else if (value.includes('Danau')){
        theColor = '#889cb5'
      }else if (value.includes('empadan sungai')){
        theColor = '#ccd4de'
      }else if (value.includes('Sungai')){
        theColor = '#ccd4de'
      }else if (value.includes('Badan Air')){
        theColor = '#ccd4de'
      }else {
        theColor = 'yellow'
      }
       return theColor
    },

    async valueChangedLuasanInfraGeoJson(value){
      // console.log(JSON.stringify(value))
      // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
      // this.geojson = await response.json();

      const response = await fetch(FileService.file_url(value.fileName));

      console.log(JSON.stringify(response))

      if (value.selected ===true){
        const geojsonResponse = await response.json();
        this.itemSpaMainGeojson.push( {id: value.id, data: geojsonResponse, lineColor: value.remark1, fillColor: value.remark2} )

      }else {
        // this.geojson = {}

        const index = this.itemSpaMainGeojson.findIndex(item => item.id === value.id)
        this.itemSpaMainGeojson.splice(index, 1)
      }
    },

    async valueChangedLuasanKomoditiGeoJson(value){
      // console.log(JSON.stringify(value))
      // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
      // this.geojson = await response.json();

      const response = await fetch(FileService.file_url(value.fileName));

      // console.log(response)

      if (value.selected ==true){
        const geojsonResponse = await response.json();
        this.itemSpaMainGeojson.push( {id: value.id, data: geojsonResponse, lineColor: value.remark1, fillColor: value.remark2} )

      }else {
        // this.geojson = {}

        const index = this.itemSpaMainGeojson.findIndex(item => item.id === value.id)
        this.itemSpaMainGeojson.splice(index, 1)
      }
    },

    valueChangedInfraTypeMarker(value){
      // console.log(JSON.stringify(value))

      if (value.selected ===true){
        this.finfratructureTypesSelected.push(value.id)
      }else {
        const index = this.finfratructureTypesSelected.findIndex(itemId => itemId === value.id)
        this.finfratructureTypesSelected.splice(index, 1)
      }

      if (this.finfratructureTypesSelected.length === 0) {
        this.finfratructures = []
        return
      }

      // console.log(JSON.stringify(this.itemModified.fareaBean))
      FInfrastructureService.getAllFInfrastructureByTypesAndFareaBeanPublic(this.finfratructureTypesSelected, this.itemModified.fareaBean).then(
          response =>{
            // console.log(JSON.stringify(response))
            this.finfratructures = response.data

          }
      )
    },

    valueChangedKomoditiTypeMarker(value){
      // console.log(JSON.stringify(value.id))

      this.fkomoditiTypesSelected = []
      if (value.selected ===true){
        this.fsectorTypesSelected.push(value.id)
      }else {
        const index = this.fsectorTypesSelected.findIndex(itemId => itemId === value.id)
        this.fsectorTypesSelected.splice(index, 1)
      }

      this.fsectorTypesSelected.forEach( sectorId => {
        this.computedItemsFKomoditiType.filter(x => x.fsectorTypeBean==sectorId).forEach( komodityType =>
            this.fkomoditiTypesSelected.push(komodityType.id)
        )
      })

      // console.log(JSON.stringify(this.fkomoditiTypesSelected.length))
      // console.log(JSON.stringify(this.fkomoditiTypesSelected.length))

      if (this.fkomoditiTypesSelected.length === 0) {
        this.fkomodities = []
        return
      }

      FKomoditiService.getAllFKomoditiByTypesAndFareaBeanPublic(this.fkomoditiTypesSelected, this.itemModified.fareaBean).then(
          response =>{
            // console.log(JSON.stringify(response.data))
            // console.log(response.data.length)
            this.fkomodities = []
            response.data.forEach(item => {

              let randomInt = this.getRandomInt(-888, 888) * 0.00001

              // console.log(JSON.stringify(this.lookupFSubArea(item.fsubAreaBean)))
                item.lat = randomInt + this.lookupFSubArea(item.fsubAreaBean).lat
                item.lon = randomInt + this.lookupFSubArea(item.fsubAreaBean).lon

              // console.log(item.lat)
              // console.log(randomInt)
              //-8.5382168973674

                // item.lat =123
                // item.lon = 234
                if (item.lat != 0 && item.lon != 0 &&  !isNaN(item.lat) && !isNaN(item.lon)){
                  this.fkomodities.push(item)
                }
            })

            // console.log(this.computedItemsFSubArea.length)

            // this.fkomodities = response.data


          }
      )



    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    jsonToHtmlTable(jsonValue){
      // console.log(`hello bos: ${JSON.stringify(jsonValue)}`)

      // for (const meta in myObj) {
      //   console.log(meta)
      // }

      const myObj = jsonValue
      // let  text = "<table>"
      // text += "<tr><th>Desc</th><th>Nilai</th></tr>"
      //
      // text += "<tr>"
      // for (const meta in myObj) {
      //   text += "<td>"
      //   text += `${meta}`
      //   text += "</td>"
      //
      //   text += "<td>"
      //   text += `${myObj[meta]}`
      //   text += "</td>"
      //
      //   // text +=  meta + " : " + myObj[meta] + "<br>";
      // }
      // text += "</tr>"
      //
      // text += "</table>"

      let text = "<table style='width: 300px'>\n" +
          "         <tr>\n" +
          "            <td style='width:40%'>::</td>\n" +
          "            <th></th>\n" +
          "         </tr>\n"
          for (const meta in myObj) {
          text += "         <tr>\n" +
                  "            <td style='color: #777777'>" + meta + "</td>\n" +
                  "            <td>: &nbsp;" + myObj[meta]+ "</td>\n" +
                  "         </tr>\n"
                }
          text += "      </table>"

      return text
    },

    openGoogleMap(item) {

      const urlSuffix = `${item.lat},${item.lon}&z=15`
      window.open(
          "https://maps.google.com/maps?q=" + urlSuffix,
          "_blank"
      );
    },

    fetchParent(){

    }

  },

  mounted() {
    // console.log(JSON.stringify(this.itemsFile))


  },

  async created () {
    // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
    // this.geojson = await response.json();

    // console.log(JSON.stringify(this.itemsFile))

  }


};
</script>

<style scoped>

</style>
