<template>
  <v-carousel
      continuous
      cycle
      show-arrows-on-hover
      hide-delimiter-background
      interval="2500"
  >
      <v-carousel-item
          v-for="(item, i) in itemsFile"
          :key="i"
          reverse-transition="slide-x-transition"
          transition="slide-x-transition"
      >
        <v-img
            :src="lookupImageUrl(item)"
            :lazy-src="lookupImageUrlLazy(item)"
            height="100%"
        ></v-img>
      </v-carousel-item>

  </v-carousel>

  </template>

<script>
import FileService from "@/services/apiservices/file-service";

export default {
  props:{
    itemsFile: []
  },
  name: "PotensiDetailCaousel",
  data() {
    return {
    }
  },
  methods:{
    lookupImageUrl(item){
      // console.log(item.fileName)
      if (item.fileName===undefined){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_high(item.fileName)
      }
    },
    lookupImageUrlLazy(item){
      return FileService.image_url_verylow(item.fileName)
    },

  }
}
</script>

<style scoped>

</style>