<template>
  <div>
    <PotensiDetail></PotensiDetail>
  </div>
</template>

<script>
import PotensiDetail from "@/components/public_potensi_detail/PotensiDetail";
export default {
  name: "PotensiDetailView",
  components: {
    PotensiDetail,
  },
};
</script>

<style scoped>
</style>